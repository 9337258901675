import React from 'react';
export const Certificates = (props) => {


	return (
		<div className="">
            <img src="assets/layout/images/cherise-logo-white-old.png" style={{ padding:"20px"}} alt="logo" />
			<iframe height='500px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/parimal4/member/report-embed/CVES_Publish_Certificate_Report/QTe14BGPu4DgujCxCPsAYqyVap8AN8xHj2pYPCnrmZwbNdsRKye3gZCatpjRmpVUFnSMxGOz20snfwBxgmtrbPbGETyfDbxwGW5B'></iframe>
		</div>
	)
}