import React, {useEffect, useState} from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { BestCupPerformance } from "./BestCupPerformance";
// Common
import CommonCal from "../../service/common/CommonCal";
// Componet
import { PerformanceStat } from "./PerformanceStat";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices =  new ApiServices();
const commonCal = new CommonCal();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const OverallPerformance = () => {

    const [multiselectValue, setMultiselectValue] = useState({ name: 'This Month', code: 'this_month' });
    const [performanceStats,setPerformanceStats] =useState(null)
    const [allUsers,setAllUsers] = useState(null)
    const [customFilterDialog,setCustomFilterDialog] = useState(false)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [loading, setLoading] = useState(true);
    const [dateData, setDateData] = useState(true);
    
    const multiselectValues = [
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        { name: 'last Month', code: 'last_month' },
        { name: 'Custom', code: 'custom' }
    ];

    const getPerformanceFn = async(data) =>{
        data.userType = initialValue.userType
        if(initialValue.userType == 'accountmanager'){

            const response = await apiServices.getPerformanceByAccMan({...data,userid:initialValue.userid});
            const performance = response?.data?.data
            console.log(performance);
            setPerformanceStats(performance)


        }else{

            const response = await apiServices.getPerformance(data);
            const performance = response?.data?.data
            console.log(performance);
            setPerformanceStats(performance)
            
        }

        

        const response1 = await apiServices.getUserReduced();
        const users = response1?.data?.data;
        setAllUsers(users)
        setLoading(false)
    }

    useEffect(()=>{
        const response1 = commonCal.getDates()
        console.log(response1);
        setDateData(response1)
        getPerformanceFn({date: {startDate: response1.monthStart, endDate: response1.nowDate},userType: initialValue.userType})
    },[])


    const changeDuration = (e) =>{
        
        setMultiselectValue(e.value)
        const currdate = new Date()
        switch(e.value.code) {
            case 'this_week':
                setLoading(true)
                getPerformanceFn({date: {startDate: dateData.weekstart, endDate: dateData.weekEnd},userType: initialValue.userType})
                break;
            case 'this_month':
                setLoading(true)
                getPerformanceFn({date: {startDate: dateData.monthStart, endDate: dateData.endThisMonth},userType: initialValue.userType})
                break;
            case 'last_month':
                setLoading(true)
                getPerformanceFn({date: {startDate: dateData.lastStart, endDate: dateData.lastend},userType: initialValue.userType})
                break;
            case 'custom':
                setCustomFilterDialog(true);
                break;
            default:
                getPerformanceFn({date: {startDate: dateData.monthStart, endDate: dateData.endThisMonth},userType: initialValue.userType})
          }
    }

    const handleSubmit = (e) =>{

        e.preventDefault()
        const start = e.target.start_date.value;
        const end = e.target.end_date.value;
        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        getPerformanceFn({date: {startDate: cust_start, endDate:cust_end},userType: initialValue.userType})
        setCustomFilterDialog(false)
    }

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={multiselectValue} onChange={changeDuration} options={multiselectValues} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    ) 

    const leftToolbarTemplate = () => (<><h6 className="p-m-0">Performance</h6></>)

    return (
        <>
        <div className="p-grid crud-demo">
            <div className="p-col-12">
            <div className="card">

                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <TabView>
                    <TabPanel header="Best Performance By Cup-wise">
                        <BestCupPerformance performanceStats={performanceStats?performanceStats[0]?.bycups?.best:null} allUsers={allUsers} title={'Best Performance By Cup-wise'} id={'cup'} loading={loading}/>
                    </TabPanel>
                    <TabPanel header="Worst Performance By Cup-wise">
                        <BestCupPerformance performanceStats={performanceStats?performanceStats[0]?.bycups?.worst:null} allUsers={allUsers} title={'Worst Performance By Cup-wise'} id={'cup'} loading={loading}/>
                    </TabPanel>
                    <TabPanel header="Best Performance By Target-Wise">
                        <BestCupPerformance performanceStats={performanceStats?performanceStats[0]?.bytarget?.best:null} allUsers={allUsers} title={'Best Performance By Target-Wise'} id={'target'} loading={loading}/>
                    </TabPanel>
                    <TabPanel header="Worst Performance By Target-Wise">
                        <BestCupPerformance performanceStats={performanceStats?performanceStats[0]?.bytarget?.worst:null} allUsers={allUsers} title={'Worst Performance By Target-Wise'} id={'target'} loading={loading}/>
                    </TabPanel>
                </TabView>
                
            </div>
            </div>
        </div>

        <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter"  onHide={()=>{setCustomFilterDialog(false)}} modal className="p-fluid">
            <form onSubmit={handleSubmit}>
                <div className='p-grid'>
                    <div className='p-col-6'>
                            <label htmlFor="from">From</label>
                            <InputText id="from" type="date" name='start_date' value={customStart} onChange={(e) => {setCustomStart(e.value) } } required autoFocus/>
                    </div>
                    <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' value={customEnd} onChange={(e) => {setCustomEnd(e.value)}} required/>
                    </div>
                </div>
                <div className="p-dialog-footer p-mt-4">
                    <button className="p-button p-button-danger" onClick={()=>{setCustomFilterDialog(false)}}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
                    <button className="p-button p-button-success" type="submit"><span className="p-button-icon p-c pi pi-check p-button-icon-left"></span><span className="p-button-label p-c">Submit</span><span className="p-ink"></span></button>
                </div>
            </form>
        </Dialog>

        </>
    )
}