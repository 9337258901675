import React, { useState, useEffect, useContext } from "react";
import { SubAnalyticalStat } from "./SubAnalyticalStat";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { MultiSelect } from "primereact/multiselect";
// Components
import { AnalyticalStat } from "./AnalyticalStat";
import { AnalyticalCharts } from "./AnalyticalCharts";
// Services
import CommonCal from '../../service/common/CommonCal';
import { managementCheck } from "../../service/admin/superadmin";
import { ProgressSpinner } from 'primereact/progressspinner';

// Context
import { permissionContext } from "../../AppWrapper";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()
const commonCal = new CommonCal()
// Logged User
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);
const google = window.google = window.google ? window.google : {}

export const AnalyticsMainDev = () => {

    const rolePermissions = useContext(permissionContext);

    // const BevVendingMchOptions = [
    //     {name:'All',code:'All'},
    //     {name:'TAPRI',code:'TAPRI'},
    //     {name:'TKAFFE',code:'TKAFFE'}
    // ]

    const BevVendingMchOptions = [
        { name: 'TAPRI KIOSK', code: 'TAPRI' },
        { name: 'TKAFFE KIOSK', code: 'TKAFFE' },
        { name: 'TAPRI KETTLE', code: 'MERI_TAPRI' }
    ]

    const mchStatusOptions = [
        { name: 'Active', code: 'active' },
        { name: 'Inactive', code: 'inactive' }
    ]

    const TimeSelectValues = [
        { name: 'All', code: 'all' },
        { name: 'Today', code: 'today' },
        { name: 'Yesterday', code: 'yesterday' },
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        { name: 'Last Month', code: 'last_month' },
        { name: 'Custom', code: 'custom' }
    ]

    const TimeSelectValuesForManagementAll = [
        { name: 'All', code: 'all' },
        { name: 'Today', code: 'today' },
        { name: 'Yesterday', code: 'yesterday' },
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        // {name:'Last Month',code:'last_month'},
        { name: 'Custom', code: 'custom' }
    ]

    const TimeSelectValuesForManagementSingle = [
        { name: 'All', code: 'all' },
        { name: 'Today', code: 'today' },
        { name: 'Yesterday', code: 'yesterday' },
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        // {name:'Last Month',code:'last_month'},
        // { name: 'Custom', code: 'custom' }
    ]

    const TimeSelectValues1 = [
        { name: 'All', code: 'all' },
        { name: 'Today', code: 'today' },
        { name: 'Yesterday', code: 'yesterday' },
        { name: 'This Week', code: 'this_week' },
        { name: 'This Month', code: 'this_month' },
        { name: 'Last Month', code: 'last_month' },
        { name: 'Custom', code: 'custom' }
    ]

    const customWise = [
        { name: "Month Wise", code: 'monthwise' },
        { name: "Date Wise", code: 'datewise' },
        { name: "Quarter Wise", code: "quarterwise" }
    ]
    const customWiseManagement = [
        { name: "Month Wise", code: 'monthwise' },
        // { name: "Date Wise", code: 'datewise' },
        // { name: "Quarter Wise", code: "quarterwise" }
    ]

    const machineTypeOptions = [
        { name: "All", code: 'all' },
        { name: "Demo", code: 'demo' },
        { name: "Commercial", code: 'commercial' }
    ]

    const connectivityStatusOptions = [
        { name: "All", code: 'all' },
        { name: "Online", code: 'connected' },
        { name: "Offline", code: 'disConnected' }
    ]

    const quarterListOptions = [
        {
            name: "Q2FY24-25 ( Jul-2024 To Sep-2024 )", code: "Q2FY24-25",
            date: {
                startDate: new Date('2024-06-30T18:30:00.000Z'), //July 2024
                endDate: new Date('2024-09-30T18:29:59.000Z') // Sep 2024
            }
        },
        {
            name: "Q1FY24-25 ( Apr-2024 To Jun-2024 )", code: "Q1FY24-25",
            date: {
                startDate: new Date('2024-03-31T18:30:00.000Z'), // Apil 2024
                endDate: new Date('2024-06-30T18:29:59.000Z') // Jun 2024
            }
        },
        {
            name: "Q4FY23-24 ( Jan-2024 To Mar-2024 )",
            code: "Q4FY23-24",
            date: {
                startDate: new Date('2023-12-31T18:30:00.000Z'), // Jan 2024
                endDate: new Date('2024-03-31T18:29:59.000Z') // March 2024
            }
        },
        {
            name: "Q3FY23-24 ( Oct-2023 To Dec-2023 )",
            code: "Q3FY23-24",
            date: {
                startDate: new Date('2023-09-30T18:30:00.000Z'), // Oct 2023
                endDate: new Date('2023-12-31T18:29:59.000Z') // Dec 2023
            }
        },
        {
            name: "Q2FY23-24 ( Jul-2023 To Sep-2023 )", code: "Q2FY23-24",
            date: {
                startDate: new Date('2023-06-30T18:30:00.000Z'), //July 2023
                endDate: new Date('2023-09-30T18:29:59.000Z') // Sep 2023
            }
        },
        {
            name: "Q1FY23-24 ( Apr-2023 To Jun-2023 )", code: "Q1FY23-24",
            date: {
                startDate: new Date('2023-03-31T18:30:00.000Z'), // Apil 2023
                endDate: new Date('2023-06-30T18:29:59.000Z') // Jun 2023
            }
        },
        {
            name: "Q4FY22-23 ( Jan-2023 To Mar-2023 )",
            code: "Q4FY22-23",
            date: {
                startDate: new Date('2022-12-31T18:30:00.000Z'), // Jan 2023
                endDate: new Date('2023-03-31T18:29:59.000Z') // March 2023
            }
        },
        {
            name: "Q3FY22-23 ( Oct-2022 To Dec-2022 )",
            code: "Q3FY22-23",
            date: {
                startDate: new Date('2022-09-30T18:30:00.000Z'), // Oct 2022
                endDate: new Date('2022-12-31T18:29:59.000Z') // Dec 2022
            }
        },
        {
            name: "Q2FY22-23 ( Jul-2022 To Sep-2022 )", code: "Q2FY22-23",
            date: {
                startDate: new Date('2022-06-30T18:30:00.000Z'), //July 2022
                endDate: new Date('2022-09-30T18:29:59.000Z') // Sep 2022
            }
        },
        {
            name: "Q1FY22-23 ( Apr-2022 To Jun-2022 )", code: "Q1FY22-23",
            date: {
                startDate: new Date('2022-03-31T18:30:00.000Z'), // Apil 2022
                endDate: new Date('2022-06-30T18:29:59.000Z') // Jun 2022
            }
        },
        {
            name: "Q4FY21-22 ( Jan-2022 To Mar-2022 )",
            code: "Q4FY21-22",
            date: {
                startDate: new Date('2021-12-31T18:30:00.000Z'), // Jan 2022
                endDate: new Date('2022-03-31T18:29:59.000Z') // March 2022
            }
        },
        {
            name: "Q3FY21-22 ( Oct-2021 To Dec-2021 )",
            code: "Q3FY21-22",
            date: {
                startDate: new Date('2021-09-30T18:30:00.000Z'), // Oct 2021
                endDate: new Date('2021-12-31T18:29:59.000Z') // Dec 2021
            }
        },
        {
            name: "Q2FY21-22 ( Jul-2021 To Sep-2021 )", code: "Q2FY21-22",
            date: {
                startDate: new Date('2021-06-30T18:30:00.000Z'), //July 2021
                endDate: new Date('2021-09-30T18:29:59.000Z') // Sep 2021
            }
        },
        {
            name: "Q1FY21-22 ( Apr-2021 To Jun-2021 )", code: "Q1FY21-22",
            date: {
                startDate: new Date('2021-03-31T18:30:00.000Z'), // Apil 2021
                endDate: new Date('2021-06-30T18:29:59.000Z') // Jun 2021
            }
        },
        {
            name: "Q4FY20-21 ( Jan-2021 To Mar-2021 )", code: "Q4FY20-21",
            date: {
                startDate: new Date('2020-12-31T18:30:00.000Z'), // Jan 2021
                endDate: new Date('2021-03-31T18:29:59.000Z') // March 2021
            }
        },
        {
            name: "Q3FY20-21 ( Oct-2020 To Dec-2020 )", code: "Q3FY20-21",
            date: {
                startDate: new Date('2020-09-30T18:30:00.000Z'), // Oct 2020
                endDate: new Date('2020-12-31T18:29:59.000Z') // Dec 2020
            }
        },
        {
            name: "Q2FY20-21 ( Jul-2020 To Sep-2020 )", code: "Q2FY20-21",
            date: {
                startDate: new Date('2020-06-30T18:30:00.000Z'), //July 2020
                endDate: new Date('2020-09-30T18:29:59.000Z') // Sep 2020
            }
        },
        {
            name: "Q1FY20-21 ( Apr-2020 To Jun-2020 )", code: "Q1FY20-21",
            date: {
                startDate: new Date('2020-03-31T18:30:00.000Z'), // Apil 2020
                endDate: new Date('2020-06-30T18:29:59.000Z') // Jun 2020
            }
        },
        {
            name: "Q4FY19-20 ( Jan-2020 To Mar-2020 )", code: "Q4FY19-20",
            date: {
                startDate: new Date('2019-12-31T18:30:00.000Z'), // Jan 2020
                endDate: new Date('2020-03-31T18:29:59.000Z') // March 2020
            }
        },

    ]



    const useStatInput = { id: [], date: { startDate: "", endDate: "" }, beverage: "All", connectivity: "all" }
    const useStatInput3 = { id: [""], beverage: "All", connectivity: "all" }

    const [machineselectValue, setMachineselectValue] = useState({ displayLabel: 'All', customer: 'All' });
    const [timeselectValue, setTimeselectValue] = useState({ name: 'This Month', code: 'this_month' });

    const [resultedMachine, setResultedMachine] = useState([])
    const [masterResultedMachines, setMasterResultedMachines] = useState([])
    const [filterButton, setFilterButton] = useState(true)
    const [machineData, setMachineData] = useState(null)
    const [machinesForCount, setMachinesForCount] = useState(null)
    const [allMachineData, setAllMachineData] = useState(null)
    const [allMachines, setAllMachines] = useState(null)
    const [allMachineCount, setAllMachineCount] = useState(null)
    const [selectSingleMachineF, setSelectSingleMachineF] = useState(false);

    const [userStatParam, setUserStatParam] = useState(null);
    const [paramForDailyCups, setParamForDailyCups] = useState(null);
    const [paramForBevWiseDaily, setParamForBevWiseDaily] = useState(null);
    const [filteredDate, setFilteredDate] = useState(null)
    const [datesData, setDatesData] = useState(null)
    const [customFilterDialog, setCustomFilterDialog] = useState(false)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('this_month')
    const [customerValue, setCustomerValue] = useState(null)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [onlineData, setOnlineData] = useState(null);
    const [allCupConsumeData, setAllCupConsumeData] = useState()
    const [cupCData, setCupCData] = useState([]);
    const [TimeCData, setTimeCData] = useState([]);
    const [allBeverages, setAllBeverages] = useState(null)
    const [meriTapriMachines, setMeriTapriMachines] = useState(null)
    const [tapriMachineData, setTapriMachineData] = useState({ displayLabel: 'All' })
    const [selectedTapri, setSelectedTapri] = useState({ displayLabel: 'All' })
    const [allTapriMachines, setAllTapriMachines] = useState([])
    const [selectedTimeValue, setSelectedTimeValue] = useState({ name: 'This Month', code: 'this_month' });
    const [tapriFilterButton, setTapriFilterButton] = useState(true)
    const [activeTabNumber, setActiveTabNumber] = useState(0);
    const [bevChartData, setBevChartData] = useState(null);
    const [selectedBVMBrand, setSelectedBVMBrand] = useState(BevVendingMchOptions);
    const [selectedMachStatus, setSelectedMachStatus] = useState(mchStatusOptions);
    // Custom
    const [lastMonthsListDropDown, setLastMonthsListDropDown] = useState([]);

    const [customType, setCustomType] = useState(null)
    const [selectedCustomMonth, setSelectedCustomMonth] = useState(null)
    const [totalMachineCount, setTotalMachineCount] = useState(null)
    const [custKettleFilterDialog, setCustKettleFilterDialog] = useState(false)

    // const [selectedMachineType, setSelectedMachineType] = useState({ name:"Commercial", code : 'commercial' })
    const [selectedMachineType, setSelectedMachineType] = useState({ name: "All", code: 'all' })

    const [selectedConnectivityStatus, setSelectedConnectivityStatus] = useState({ name: 'All', code: 'all' })

    const [isKettleSelected, setIsKettleSelected] = useState(false)
    const [isKettleSelectedForStat, setIsKettleSelectedForStat] = useState(false)

    const [fMachineData, setFMachineData] = useState([]);
    const [filterMachineStatus, setFilterMachineStatus] = useState(false);
    const [selectSingleMachine, setSelectSingleMachine] = useState(false);
    const [customerClicks1, setCustomerClicks1] = useState(false);
    const [customMonthsData, setCustomMonthsData] = useState(null);
    const [customYearsData, SetCustomYearsData] = useState(null);
    const [selectedkettle, setSelectedkettle] = useState(null);
    const [dayWiseCDataSet, setDayWiseCDataSet] = useState(null);
    const [dayWiseCData, setDayWiseCData] = useState(null);
    const [totalTapriConsumption, setTotalTapriConsumption] = useState(null);
    const [tapriSuperstar, setTapriSuperstar] = useState(null);
    const [customValue, setCustomValue] = useState(false);
    const [timeselectValueF, setTimeselectValueF] = useState({ name: 'This Month', code: 'this_month' });
    const [materialType, setMaterialType] = useState([]);
    const [flavourWiseData, setFlavourWiseData] = useState([]);
    const [masterLoader, setMasterLoader] = useState(true);


    // QuarterWise Integration 

    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const [locationLoader, setLocationLoader] = useState(true);


    useEffect(() => {
        getMaterialType()
        getMachine()
        getDates()
        getTest()
        getMachineCoordinates()

        // getMachineTapri()
        // getmachineLatLong(null)
        // getkettleLatLong(null)
    }, [])


    useEffect(() => {
        // console.log('machineselectValue', machineselectValue?.displayLabel);

        if ((managementCheck() || initialValue?.userType == 'fintech' ) && machineselectValue?.displayLabel !== 'All') {
            // console.log('CUSTOM FILTER SELECTED');
            setTimeselectValue({ name: 'This Month', code: 'this_month' })
        }

    }, [machineselectValue])



    const getMaterialType = async () => {
        const response = await apiServices.getMaterialColor();
        // console.log('Material Color =>', response);
        setMaterialType(response?.data?.data);
    }

    const getTest = () => {

        // const lastMonthData = [{ name: "Jan" }, { name: "Feb" }, { name: "March" }, { name: "April" }, { name: "May" }, { name: "Jun" }, { name: "Jul" }, { name: "Aug" }, { name: "Sep" }, { name: "Oct" }, { name: "Nov" }, { name: "Dec" }]
        // const yearsData = [{ name: "2022" }, { name: "2023" }, { name: "2024" }]

        // setCustomMonthsData(lastMonthData)
        // SetCustomYearsData(yearsData)

        var theMonths = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
        var now = new Date();


        const arrOfValues = [];
        for (var i = 0; i < 60; i++) {
            var future = new Date(now.getFullYear(), now.getMonth() + i, 1);
            // console.log("future", future);
            var month = theMonths[future.getMonth()];
            var year = future.getFullYear() - 5;
            arrOfValues.push({ name: `${month} ${year}`, code: `${month}-${year}` })
        }
        // arrOfValues.splice(0, 0); //removed first 5, beacuse i need arround 18 months data

        const customMonths = arrOfValues.reverse();
        const lastArr = customMonths.map((x, i) => { return { ...x, index: i } })
        // console.log('lastArr', lastArr);
        setLastMonthsListDropDown(lastArr);

    }

    const getCustomDates = (i) => {
        const currdate = new Date();
        const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth() - (i + 1), 1).setHours(0, 0, 0, 0);
        const lastMonthEnd = new Date(currdate.getFullYear(), currdate.getMonth() - (i), 0).setHours(23, 59, 59, 0);
        const lastStart = new Date(lastMonthStart).toISOString();
        const lastend = new Date(lastMonthEnd).toISOString();

        console.log({ startDate: lastStart, endDate: lastend })
        return { startDate: lastStart, endDate: lastend }
    }

    const getOnlineStat = async (machineData, date) => {

        if (machineData?.mchmode?.paymode?.online == true && machineData?.mchmode?.paymode?.rfid == true) {

            const data = { machineid: machineData.id, date }
            const response = await apiServices.empConsumptionCount(data);
            const data1 = { id: machineData.id, date }
            const response1 = await apiServices.getTransactionStats(data1);
            setOnlineData({ online: response?.data?.data[0]?.count ? response?.data?.data[0]?.count : null, paytm: response1?.data?.data[0]?.succ ? response1?.data?.data[0]?.succ : null })

        } else {
            setOnlineData(null)
        }
    }

    const getMachineCoordinates = async (data) => {

        let sub_status = null;
        if (isKettleSelected) {
            if (selectedMachStatus?.length == 1) {
                sub_status = selectedMachStatus[0].code;
            }
        }

        let connectivity = 'all';

        if (selectedConnectivityStatus?.code == 'disConnected') {
            connectivity = selectedConnectivityStatus?.code;
        } else if (selectedConnectivityStatus?.code == 'connected') {
            connectivity = selectedConnectivityStatus?.code;
        }

        const brandOptions = selectedBVMBrand?.map((x) => { return x.code })

        let payload;
        if (sub_status) {
            payload = {
                "userId": initialValue?.userid,
                "userType": initialValue?.userType,
                "brand": brandOptions,
                "subscriptionType": selectedMachineType?.code == 'commercial' ? "permanent" : selectedMachineType?.code,
                "subscription_status": sub_status,
                "connectivity": connectivity
            }
        } else {
            payload = {
                "userId": initialValue?.userid,
                "userType": initialValue?.userType,
                "brand": brandOptions,
                "subscriptionType": selectedMachineType?.code == 'commercial' ? "permanent" : selectedMachineType?.code,
                "connectivity": connectivity
            }
        }


        // console.log("Machine Location", payload);


        setLocationLoader(true);

        const response = await apiServices.getCvesLocations(payload);

        setLocationLoader(false)

        let arr = [];
        response?.data?.data?.forEach((x) => {
            arr.push({
                ...x,
                companyname: x.siteName,
                facid: x.facility?.facid,
                facname: x.facility?.facname,
                accountid: x.Customer_Account_ID,
                subid: x.subid
            })
        });

        const coordinates = (data != null) ? arr.filter((x) => { return x.id == data; }) : arr;

        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 4,
            center: { lat: 18.5979754, lng: 74.004489 },
            mapTypeId: google.maps.MapTypeId.HYBRID
        });


        for (let i = 0; i < coordinates.length; i++) {

            const img = coordinates[i].connectionMode == "disConnected" ? '../../assets/demo/images/Icon/offline_location.png' : '../../assets/demo/images/Icon/online_location.png';

            const contentString =
                '<div id="map-content">' +
                `<h6 id="firstHeading" class="firstHeading">CVES ID : ${coordinates[i].id} (${coordinates[i].subscriptionType == 'permanent' ? 'Commercial' : coordinates[i].subscriptionType}) </h6>` +
                '<div id="bodyContent">' +
                `<p>Subscription ID : ${coordinates[i].subid}</p>` +
                `<p>Account ID : ${coordinates[i].accountid ? coordinates[i].accountid : 'NA'}</p>` +
                `<p>Account Name : ${coordinates[i].companyname}</p>` +
                `<p>Facility ID : ${coordinates[i].facid}</p>` +
                `<p>Facility Name : ${coordinates[i].facname}</p>` +
                "</div>" +
                "</div>";

            const infowindow = new google.maps.InfoWindow({ content: contentString, ariaLabel: "Uluru" });

            const marker = new google.maps.Marker({
                position: { lat: parseFloat(coordinates[i].location?.lat), lng: parseFloat(coordinates[i]?.location?.lng) },
                icon: img,
                map: map,
                title: 'CVES ID: ' + coordinates[i].id + '\n' + 'Subscription Type: ' + (coordinates[i].subscriptionType == 'permanent' ? 'Commercial' : 'Demo') + '\n' + 'Subscription ID: ' + coordinates[i].subid + '\n' + 'Account ID: ' + (coordinates[i].accountid ? coordinates[i].accountid : 'NA') + '\n' + 'Account Name: ' + coordinates[i].companyname + '\n' + 'Facility ID: ' + coordinates[i].facid + '\n' + 'Facility Name: ' + coordinates[i].facname,
            });

            marker.addListener('click', function () { infowindow.open(map, marker) });
        }

    }

    const getmachineLatLong = async (data) => {

        // console.log(data)
        const response = await apiServices.getMachineLocations();
        // console.log(response.data.data)
        // const check = response.data.data.filter((x)=>{ return (x.id == 'MT-45') })
        // console.log(check);
        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response1 = await apiServices.findMachines(filters);

        // const response1 = await apiServices.findMachines();
        // const kettleResponse = await apiServices.findMachines({app_cat: "Tapri Kettle"});
        // console.log(kettleResponse.data.data)
        const localArray = response?.data?.data.filter((value) => { return value.location && value.id != null });
        // console.log(localArray)
        const response4 = await apiServices.getBulkMachines();
        console.log(response4)

        // const response4 = await apiServices.getIoMachines();
        // console.log(response4?.data?.data);
        // const xx = response1.data.data.filter((x)=>{ return x.id == 'MT-17'})
        // console.log(xx);

        let newArr;

        if (selectedBVMBrand?.length == 1) {

            if (selectedMachineType?.code == 'all') {
                newArr = response1?.data?.data?.filter((x) => {
                    return x.brand == selectedBVMBrand[0]?.code;
                })

            } else if (selectedMachineType?.code == 'demo') {

                newArr = response1?.data?.data?.filter(checkDemoMachine).filter((x) => {
                    return x.brand == selectedBVMBrand[0]?.code;
                })

            } else if (selectedMachineType?.code == 'commercial') {
                newArr = response1?.data?.data?.filter(checkpermaMachine).filter((x) => {
                    return x.brand == selectedBVMBrand[0]?.code;
                })
            }

        } else if (selectedBVMBrand?.length == 2) {

            if (selectedBVMBrand.some(function (o) { return o["code"] === "TAPRI" }) && selectedBVMBrand.some(function (o) { return o["code"] === "TKAFFE" })) {

                const filteredData = response1?.data?.data?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'TKAFFE') })

                if (selectedMachineType?.code == 'all') {
                    newArr = filteredData?.filter((x) => { return (x.brand == 'TAPRI' || x.brand == 'TKAFFE') })

                } else if (selectedMachineType?.code == 'demo') {

                    newArr = filteredData?.filter(checkDemoMachine).filter((x) => { return (x.brand == 'TAPRI' || x.brand == 'TKAFFE') })

                } else if (selectedMachineType?.code == 'commercial') {

                    newArr = filteredData?.filter(checkpermaMachine).filter((x) => { return (x.brand == 'TAPRI' || x.brand == 'TKAFFE') })
                }


            } else if (selectedBVMBrand.some(function (o) { return o["code"] === "TAPRI" }) && selectedBVMBrand.some(function (o) { return o["code"] === "MERI_TAPRI" })) {

                const filteredData = response1?.data?.data?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'MERI_TAPRI') })

                if (selectedMachineType?.code == 'all') {
                    newArr = filteredData?.filter((x) => { return (x.brand == 'TAPRI' || x.brand == 'MERI_TAPRI') })

                } else if (selectedMachineType?.code == 'demo') {

                    newArr = filteredData?.filter(checkDemoMachine).filter((x) => { return (x.brand == 'TAPRI' || x.brand == 'MERI_TAPRI') })

                } else if (selectedMachineType?.code == 'commercial') {

                    newArr = filteredData?.filter(checkpermaMachine).filter((x) => { return (x.brand == 'TAPRI' || x.brand == 'MERI_TAPRI') })
                }

            } else if (selectedBVMBrand.some(function (o) { return o["code"] === "TKAFFE" }) && selectedBVMBrand.some(function (o) { return o["code"] === "MERI_TAPRI" })) {

                const filteredData = response1?.data?.data?.filter((x) => { return (x?.brand == 'TKAFFE' || x?.brand == 'MERI_TAPRI') })

                if (selectedMachineType?.code == 'all') {
                    newArr = filteredData?.filter((x) => { return (x.brand == 'TKAFFE' || x.brand == 'MERI_TAPRI') })

                } else if (selectedMachineType?.code == 'demo') {

                    newArr = filteredData?.filter(checkDemoMachine).filter((x) => { return (x.brand == 'TKAFFE' || x.brand == 'MERI_TAPRI') })

                } else if (selectedMachineType?.code == 'commercial') {

                    newArr = filteredData?.filter(checkpermaMachine).filter((x) => { return (x.brand == 'TKAFFE' || x.brand == 'MERI_TAPRI') })
                }
            }

        } else if (selectedBVMBrand?.length == 3) {

            const filteredData = response1?.data?.data?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'TKAFFE' || x?.brand == 'MERI_TAPRI') })

            if (selectedMachineType?.code == 'all') {
                newArr = filteredData?.filter((x) => { return (x?.brand == 'TAPRI' || x.brand == 'TKAFFE' || x.brand == 'MERI_TAPRI') })

            } else if (selectedMachineType?.code == 'demo') {

                newArr = filteredData?.filter(checkDemoMachine).filter((x) => { return (x?.brand == 'TAPRI' || x.brand == 'TKAFFE' || x.brand == 'MERI_TAPRI') })

            } else if (selectedMachineType?.code == 'commercial') {

                newArr = filteredData?.filter(checkpermaMachine).filter((x) => { return (x?.brand == 'TAPRI' || x.brand == 'TKAFFE' || x.brand == 'MERI_TAPRI') })
            }
        }

        console.log(newArr);

        var arr = [];
        newArr.forEach((element) => {
            const machineId = element.id;
            // console.log(element?.subid);

            localArray?.map((x) => {
                if (machineId == x.id) {
                    // arr.push({...x,companyname:element.siteName?element.siteName:'CIPL'})

                    arr.push({
                        ...x,
                        companyname: element.siteName ? element.siteName : 'CIPL',
                        facid: element?.subscription?.facilitydata?.facid,
                        facname: element?.subscription?.facilitydata?.facname,
                        accountid: element?.Customer_Account_ID ? element?.Customer_Account_ID : 'NA',
                        subid: element?.subid ? element?.subid : 'NA'
                    })
                }
            });
        });

        // console.log(arr);

        // var kettleArr = [];
        // kettleResponse?.data?.data.forEach((element) => {
        //     const machineId = element.id;
        //     localArray?.map((x) => {   
        //         if(machineId == x.id){
        //             kettleArr.push({...x,companyname:element.siteName?element.siteName:'CIPL'}) 
        //         }
        //     });
        // });


        if (managementCheck()) {

            var arr4 = [];
            let i = 450;
            response4?.data?.data.forEach((element) => {

                console.log(element);

                const defaultdata4 = {
                    location: element.location,
                    id: 'CH-' + i,
                    connectionMode: 'Connected',
                    companyname: element.distributor
                }

                let location;
                if (i == 388 || i == 371 || i == 384 || i == 389 || i == 383 || i == 387 || i == 311 || i == 385 || i == 390) {
                    location = { lat: '', lng: '' }
                } else {
                    location = { lat: element.location?.latitude, lng: element.location?.longitude }
                }

                arr4.push({ ...defaultdata4, location: location })
                i++;
            });

            console.log(arr4)

            // const arr4 = [];
            // response4?.data?.data.forEach((element) => {

            //     console.log(element);

            //     const defaultdata4 = 
            //     {
            //         location: element.location,
            //         // id: id,
            //         connectionMode: element.connectionMode == 'disConnected' ? 'disConnected' : 'Connected',
            //         companyname: element.siteName
            //     }

            //     arr4.push(defaultdata4);
            // })
            // console.log(arr4);

            var arr = [...arr, ...arr4];
        }

        console.log(data);

        if (data != null) {
            var arr = arr.filter(filterdat);
            function filterdat(machine) { if (machine.id == data) { return machine.id } }

            if (arr.length == 0) {
                const mac = response1.data.data.filter(fil)
                function fil(machine) { if (machine.id == data) { return machine.id } }
                console.log(mac);
                const defaultdata = {
                    location: { lat: 18.6012447, lng: 74.0059544, time: '2021-03-12T09:04:04.282Z' },
                    id: data,
                    connectionMode: mac[0]?.connectionMode,
                    companyname: mac[0]?.siteName,
                }

                arr.push(defaultdata)
            }
        }

        const centerOf = { lat: 18.5979754, lng: 74.004489 };

        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 4,
            center: centerOf,
            // mapTypeId: 'satellite',
            mapTypeId: google.maps.MapTypeId.HYBRID
        });

        console.log(arr);

        for (let i = 0; i < arr.length; i++) {

            const img = arr[i].connectionMode == "disConnected" ? '../../assets/demo/images/Icon/offline_location.png' : '../../assets/demo/images/Icon/online_location.png';

            const marker = new google.maps.Marker({
                position: { lat: parseFloat(arr[i].location?.lat), lng: parseFloat(arr[i]?.location.lng) },
                icon: img,
                map: map,
                title: 'CVES ID: ' + arr[i].id + '\n' + 'Subscription ID: ' + arr[i].subid + '\n' + 'Account ID: ' + (arr[i].accountid ? arr[i].accountid : 'NA') + '\n' + 'Account Name: ' + arr[i].companyname + '\n' + 'Facility ID: ' + arr[i].facid + '\n' + 'Facility Name: ' + arr[i].facname,
            });
        }
    }

    const returnTheMap = (data) => {
        console.log(data);
        console.log(selectedBVMBrand);
        let newArr;
        if (selectedMachineType?.code == 'all') {
            newArr = data?.filter((x) => {
                return x.brand == selectedBVMBrand?.code;
            })

            // console.log(newArr);


        } else if (selectedMachineType?.code == 'demo') {

            newArr = data?.filter(checkDemoMachine).filter((x) => {
                return x.brand == selectedBVMBrand?.code;
            })

        } else if (selectedMachineType?.code == 'commercial') {
            newArr = data?.filter(checkpermaMachine).filter((x) => {
                return x.brand == selectedBVMBrand?.code;
            })
        }

        console.log(newArr);

        return newArr;
    }

    const getkettleLatLong = async (data) => {

        console.log(data)
        // console.log(data)
        const response = await apiServices.getMachineLocations();
        console.log(response.data.data)
        // const response1 = await apiServices.findMachines();
        // const response1 = await apiServices.findMachines({app_cat: "Tapri Kettle"});
        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response1 = await apiServices.findMachines(filters);

        // console.log(kettleResponse.data.data)
        const localArray = response?.data?.data.filter((value) => { return value.location && value.id != null });
        console.log(localArray)

        let machinesData;

        if (selectedTapri?.displayLabel == 'Demo') {
            machinesData = response1?.data?.data.filter((m) => { return m.datastore.stage == "finishedgoods" && m.subscription && m.subscription.package.site == "demo" && m.siteName != "" })
        } else if (selectedTapri?.displayLabel == 'Commercial') {
            machinesData = response1?.data?.data.filter((x) => { return x.datastore?.stage == "finishedgoods" && x.subscription?.package.site == "permanent" && x.siteName != "" })
        } else {
            machinesData = response1?.data?.data;
        }

        const response4 = await apiServices.getBulkMachines();
        // console.log(response4)
        // const xx = response1.data.data.filter((x)=>{ return x.id == 'MT-17'})
        // console.log(xx);

        var arr = [];
        machinesData.forEach((element) => {
            const machineId = element.id;
            localArray?.map((x) => {
                if (machineId == x.id) {
                    arr.push({ ...x, companyname: element.siteName ? element.siteName : 'CIPL' })
                }
            });
        });

        console.log(arr);

        if (managementCheck()) {

            var arr4 = [];
            let i = 300;
            response4?.data?.data.forEach((element) => {
                const defaultdata4 = {
                    location: element.location,
                    id: 'CH-' + i,
                    connectionMode: 'Connected',
                    companyname: element.distributor
                }

                let location;
                if (i == 388 || i == 371 || i == 384 || i == 389 || i == 383 || i == 387 || i == 311 || i == 385 || i == 390) {
                    location = { lat: '', lng: '' }
                } else {
                    location = { lat: element.location?.latitude, lng: element.location?.longitude }
                }

                arr4.push({ ...defaultdata4, location: location })
                i++;
            });

            console.log(arr4)
            var arr = [...arr, ...arr4];
        }

        if (data != null) {
            var arr = arr.filter(filterdat);
            function filterdat(machine) { if (machine.id == data) { return machine.id } }

            if (arr.length == 0) {
                const mac = response1.data.data.filter(fil)
                function fil(machine) { if (machine.id == data) { return machine.id } }
                const defaultdata = {
                    location: { lat: 18.6012447, lng: 74.0059544, time: '2021-03-12T09:04:04.282Z' },
                    id: data,
                    connectionMode: mac[0]?.connectionMode,
                    companyname: mac[0]?.siteName
                }
                arr.push(defaultdata)
            }
        }

        const centerOf = { lat: 18.5979754, lng: 74.004489 };

        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 4,
            center: centerOf,
            // mapTypeId: 'satellite',
            mapTypeId: google.maps.MapTypeId.HYBRID
        });

        console.log(arr);

        for (let i = 0; i < arr.length; i++) {

            const img = arr[i].connectionMode == "disConnected" ? '../../assets/demo/images/Icon/offline_location.png' : '../../assets/demo/images/Icon/online_location.png';

            const marker = new google.maps.Marker({
                position: { lat: parseFloat(arr[i].location.lat), lng: parseFloat(arr[i].location.lng) },
                icon: img,
                map: map,
                title: 'CVES Id: ' + arr[i].id + '\n' + 'Account Name: ' + arr[i].companyname,
            });
        }
    }

    const getDates = async () => {
        const response = commonCal.getDates()
        // console.log(response);
        setDatesData(response);
    }

    // const getMachineTapri = async () => {

    //     const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
    //     const response = await apiServices.findMachines(filters);
    //     // console.log(response?.data?.data);
    //     setMeriTapriMachines(response?.data?.data)
    //     const onBoardmachines = response?.data?.data?.filter((m) => { return m?.datastore?.stage == "finishedgoods" && m?.subscription && (m?.subscription?.package?.site == "demo" || m?.subscription?.package?.site == "permanent") && m?.siteName != "" })
    //     const filtered = onBoardmachines.map((x) => { return { ...x, displayLabel: x.id + ' / ' + x.subid + ' / ' + x.siteName }; })
    //     filtered?.unshift({ displayLabel: 'All' }, { displayLabel: 'Demo' }, { displayLabel: 'Commercial' })
    //     console.log(filtered);
    //     setTapriMachineData(filtered);
    //     let result = filtered?.filter(checkMachine).map(a => a?.id);
    //     console.log(result);
    //     setAllTapriMachines(result)
    //     setTotalMachineCount(result)
    //     const currdate = new Date()
    //     const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
    //     const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
    //     const monthStart = new Date(firstDay).toISOString();
    //     const endStart = new Date(lastDay).toISOString();
    //     const date = { startDate: monthStart, endDate: endStart };
    //     getUsageStatTapri({ id: result, date: date })
    // }

    const getUsageStatTapri = async (data) => {
        setFilteredDate(data?.date)
        // var response = await apiServices.getUserStats(data);
        const response = await apiServices.getUserStatsForMeriTapri(data);
        console.log(response?.data?.data);
        setTotalTapriConsumption(response?.data?.data?.usagesStats[0]?.totalCup)
        const superstar = response?.data?.data?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
        // console.log(superstar);
        superstar && setTapriSuperstar(superstar[0]?._id)
        setBevChartData(response?.data?.data?.brvStats)
    }

    const getMachine = async () => {

        const filters = { brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
        const response = await apiServices.onboardedMachines(filters);
        const response1 = await apiServices.allUsers({ platform: "dashboard", index: 0, count: 2000 });

        let cvesArrayList = []

        response?.data?.data?.forEach((x) => {
            response1?.data?.data.filter((y) => {
                if (x.customer === y.userid) {
                    cvesArrayList.push(
                        {
                            ...x,
                            accountName: y.name,
                            companyName: y?.userType == 'customer' ? y?.billing_details?.company || x.siteName : x.siteName,
                            facilityName: x?.subscription?.facilitydata?.facname || 'NA',
                            addr: y?.addr?.addr
                        }
                    )
                }
            })
        })
        // console.log("Array CVES", cvesArrayList);
        setMachineData(cvesArrayList);
        setMachinesForCount(cvesArrayList)
        const result = cvesArrayList.map(a => a.id);
        // console.log("result", result.toString());
        setAllMachineData(result)
        setAllMachines(result)
        setAllMachineCount(result)
        thisMonthData(result)
        getResultedMachines(cvesArrayList)
        getInitialData(result)
    }

    const getInitialData = (machineData) => {

        if (initialValue?.userType == 'invenstor') {
            setTimeselectValue({ name: 'All', code: 'all' });
            setUserStatParam({ params: { ...useStatInput3, id: machineData, type: 'all' }, time: 'all' })
            setParamForDailyCups({ id: machineData })
        } else {
            const currdate = new Date()
            const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
            const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
            const monthStart = new Date(firstDay).toISOString();
            const endStart = new Date(lastDay).toISOString();
            const date = { startDate: monthStart, endDate: endStart };
            setFilteredDate(date)
            // setUserStatParam({...useStatInput,id:machineData,date:date},'this_month')
            setUserStatParam({ params: { ...useStatInput, id: machineData, date: date, type: 'all' }, time: 'this_month', })
            setParamForDailyCups({ id: machineData, date: date })
            setParamForBevWiseDaily({ filter: 'chart', id: machineData, date: date })
            // getmachineStats({ ...useStatInput, id: machineData, date });
        }


    }

    const getResultedMachines = (data) => {
        var res = data?.filter(checkMachine);
        var res = res?.map((x) => {
            const subid = x.subid ? x.subid : 'CIPL';
            return { ...x, displayLabel: x.id + ' / ' + subid + ' / ' + x.companyName };
        });

        if (initialValue?.userType == 'owner' || initialValue?.userType == 'operation') {
            // res?.unshift( {displayLabel : 'All',customer : 'All'}, {displayLabel : 'Demo',customer : 'Demo'} , {displayLabel : 'Commercial',customer : 'Commercial'} )
            res?.unshift({ displayLabel: 'All', customer: 'All' })
            setResultedMachine(res)
            setMasterResultedMachines(res)
        } else {
            res?.unshift({ displayLabel: 'All', customer: 'All' })
            setResultedMachine(res)
            setMasterResultedMachines(res)
        }
    }

    const thisMonthData = (result) => {

        const iddata = filterMachineStatus ? fMachineData : result;
        const currdate = new Date()
        const firstDay = new Date(currdate.getFullYear(), currdate.getMonth(), 1).setHours(0, 0, 0, 0);
        const lastDay = new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0).setHours(23, 59, 59, 0);
        const monthStart = new Date(firstDay).toISOString();
        const endStart = new Date(lastDay).toISOString();
        const date = { startDate: monthStart, endDate: endStart };
        // console.log(date);

        // setFilteredDate(date)
        // getLiveUserStats2({...useStatInput,id:iddata,date})
        // getmachineStats({...useStatInput,id:iddata,date});
    }

    const getmachineStats = async (data) => {
        const response = await apiServices.getMachineConsumption(data);
        console.log(response?.data?.data);
        const comsumptionChart = response?.data?.data.map((x) => { return { ...x, Charttime: new Date(x.time).toLocaleString() } });
        setAllCupConsumeData(comsumptionChart)

        let Cupresult = response?.data?.data?.map(a => a.cups);
        let Timeresult = response?.data?.data?.map(a => new Date(a.time).toLocaleString());
        setCupCData(Cupresult)
        setTimeCData(Timeresult)
    }

    function checkMachine(machine) { return machine?.siteName != "" }

    function checkDemoMachine(machine) { return machine?.site == 'demo' && machine.siteName != '' };

    function checkpermaMachine(machine) { return machine?.site == 'permanent' && machine.siteName != '' };

    const changeBVMBrand = (e) => {
        console.log(e.value);
        if (e.value?.length > 0) {
            // setSelectedBVMBrand(e.value)
            setMachineselectValue({ displayLabel: 'All', customer: 'All' })

            e.value?.length > 0 ? setFilterButton(false) : setFilterButton(true);

            if (e.value?.length == 1) {
                setSelectedBVMBrand(e.value)

                e.value[0]?.code == 'MERI_TAPRI' ? setIsKettleSelected(true) : setIsKettleSelected(false)
                e.value[0]?.code == 'MERI_TAPRI' && setSelectedMachStatus(mchStatusOptions);

                console.log(e.value[0]?.code);
                const filteredData = machineData?.filter((x) => { return x?.brand == e.value[0]?.code })
                console.log(filteredData);
                filterByBrand(selectedMachineType, filteredData);

            } else if (e.value?.length == 2) {
                setSelectedBVMBrand(e.value)
                setIsKettleSelected(false)

                console.log(machineData);

                if (e.value.some(function (o) { return o["code"] === "TAPRI" }) && e.value.some(function (o) { return o["code"] === "TKAFFE" })) {

                    const filteredData = machineData?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'TKAFFE') })
                    filterByBrand(selectedMachineType, filteredData);

                } else if (e.value.some(function (o) { return o["code"] === "TAPRI" }) && e.value.some(function (o) { return o["code"] === "MERI_TAPRI" })) {

                    const filteredData = machineData?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'MERI_TAPRI') })
                    filterByBrand(selectedMachineType, filteredData);

                } else if (e.value.some(function (o) { return o["code"] === "TKAFFE" }) && e.value.some(function (o) { return o["code"] === "MERI_TAPRI" })) {

                    const filteredData = machineData?.filter((x) => { return (x?.brand == 'TKAFFE' || x?.brand == 'MERI_TAPRI') })
                    filterByBrand(selectedMachineType, filteredData);
                }

            } else if (e.value?.length >= 3) {
                setSelectedBVMBrand(BevVendingMchOptions)
                setIsKettleSelected(false)

                if (e.value.some(function (o) { return o["code"] === "TAPRI" }) && e.value.some(function (o) { return o["code"] === "TKAFFE" }) && e.value.some(function (o) { return o["code"] === "MERI_TAPRI" })) {
                    const filteredData = machineData?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'TKAFFE' || x?.brand == 'MERI_TAPRI') })
                    // const filteredData = machineData;
                    console.log(filteredData);
                    filterByBrand(selectedMachineType, filteredData);
                }
            }
        } else {
            setSelectedBVMBrand(null)
            setFilterButton(true)
            setIsKettleSelected(false)
        }
    }

    const changeMchStatus = (e) => {
        e.value?.length > 0 ? setFilterButton(false) : setFilterButton(true);
        // setSelectedMachStatus(e.value);

        if (e.value.length > 0) {

            console.log(masterResultedMachines);
            console.log(resultedMachine);

            if (e.value.length >= 2) {
                setSelectedMachStatus(mchStatusOptions);
                setResultedMachine(masterResultedMachines)

                const result = masterResultedMachines?.map(a => a.id);
                result.shift();
                setAllMachineData(result)
                setAllMachines(result)

            } else if (e.value.length == 1) {

                setSelectedMachStatus(e.value);
                // console.log(e.value);
                if (e.value[0].code == 'active') {

                    console.log(resultedMachine);
                    const activeData = masterResultedMachines?.filter((x) => { return x?.status == 'Active' || x?.status == 'Demo' });
                    activeData?.unshift({ displayLabel: 'All', customer: 'All' })
                    console.log(activeData);
                    setResultedMachine(activeData)

                    const result = activeData?.map(a => a.id);
                    result?.shift();
                    console.log(result);

                    setAllMachineData(result)
                    setAllMachines(result)

                } else if (e.value[0].code == 'inactive') {

                    console.log(resultedMachine);
                    const InactiveData = masterResultedMachines?.filter((x) => { return x?.status == 'Inactive' });
                    InactiveData?.unshift({ displayLabel: 'All', customer: 'All' })
                    console.log(InactiveData);
                    setResultedMachine(InactiveData)

                    const result = InactiveData?.map(a => a.id);
                    result.shift();
                    setAllMachineData(result)
                    setAllMachines(result)
                }
            }
        } else {
            setSelectedMachStatus(null);
        }
    }

    // const filterByBrand = (name, data) =>{

    //     if(name.code == 'all'){
    //         const result = data?.filter(checkMachine);
    //         console.log(result);
    //         getResultedMachines(result)
    //         const resultMapped = data?.filter(checkMachine).map(a => a.id);
    //         setAllMachines(resultMapped)
    //     }

    //     if(name.code == 'demo'){

    //         const resultedDemoMachine = data.filter(checkDemoMachine);
    //         getResultedMachines(resultedDemoMachine)
    //         const resultedDemoMachineMap = data.filter(checkDemoMachine).map(a => a.id);
    //         console.log(resultedDemoMachineMap);
    //         setAllMachines(resultedDemoMachineMap)
    //     }

    //     if(name.code == 'commercial'){
    //         const resultedPermMachine = data.filter(checkpermaMachine);
    //         getResultedMachines(resultedPermMachine)
    //         const resultedPermaMachine = data.filter(checkpermaMachine).map(a => a.id);
    //         setAllMachines(resultedPermaMachine)
    //     }
    // }

    const filterByBrand = (name, data) => {
        let filteredData = [];
        let filterFunction = null;

        switch (name.code) {
            case 'all':
                filterFunction = checkMachine;
                break;
            case 'demo':
                filterFunction = checkDemoMachine;
                break;
            case 'commercial':
                filterFunction = checkpermaMachine;
                break;
            default:
                break;
        }

        if (filterFunction) {
            console.log('totalMachine Count', data?.length)
            filteredData = data?.filter(filterFunction);
            console.log('filteredData', filteredData);
            getResultedMachines(filteredData);
            const resultMapped = filteredData?.map((a) => a.id);
            setAllMachines(resultMapped);
        }
    };

    const selectMachType = (e) => {
        console.log("selectMachType", e.value.code, selectedBVMBrand?.length);
        setSelectedMachineType(e.value)
        setMachineselectValue({ displayLabel: 'All', customer: 'All' })
        selectedBVMBrand?.length > 0 ? setFilterButton(false) : setFilterButton(true);
        setSelectedMachStatus(mchStatusOptions);

        if (selectedBVMBrand?.length == 1) {

            const result = machineData.filter(checkMachine).filter((x) => { return x.brand == selectedBVMBrand[0]?.code });
            filterByBrand(e.value, result)

        } else if (selectedBVMBrand?.length == 2) {

            if (selectedBVMBrand.some(function (o) { return o["code"] === "TAPRI" }) && selectedBVMBrand.some(function (o) { return o["code"] === "TKAFFE" })) {

                const filteredData = machineData?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'TKAFFE') })
                filterByBrand(e.value, filteredData);

            } else if (selectedBVMBrand.some(function (o) { return o["code"] === "TAPRI" }) && selectedBVMBrand.some(function (o) { return o["code"] === "MERI_TAPRI" })) {

                const filteredData = machineData?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'MERI_TAPRI') })
                filterByBrand(e.value, filteredData);

            } else if (selectedBVMBrand.some(function (o) { return o["code"] === "TKAFFE" }) && selectedBVMBrand.some(function (o) { return o["code"] === "MERI_TAPRI" })) {

                const filteredData = machineData?.filter((x) => { return (x?.brand == 'TKAFFE' || x?.brand == 'MERI_TAPRI') })
                filterByBrand(e.value, filteredData);
            }

        } else if (selectedBVMBrand?.length == 3) {

            if (selectedBVMBrand.some(function (o) { return o["code"] === "TAPRI" }) && selectedBVMBrand.some(function (o) { return o["code"] === "TKAFFE" }) && selectedBVMBrand.some(function (o) { return o["code"] === "MERI_TAPRI" })) {
                // console.log('MAHCINE DATA COUNT', machineData?.length);
                const filteredData = machineData?.filter((x) => { return (x?.brand == 'TAPRI' || x?.brand == 'TKAFFE' || x?.brand == 'MERI_TAPRI') })
                // console.log('filteredData', filteredData?.length)
                filterByBrand(e.value, filteredData);
            }

        }
    }

    const handleConnectivityStatus = async (e) => {
        setSelectedConnectivityStatus(e.target.value);
        setMachineselectValue({ displayLabel: 'All', customer: 'All' });
        selectedBVMBrand?.length > 0 ? setFilterButton(false) : setFilterButton(true);
        if (e.value?.code === 'connected') {
            const filterOnline = await machineData?.filter(x => x.connectionMode != 'disConnected');
            console.log('filterOnline', filterOnline)
            getResultedMachines(filterOnline);
            const resultMapped = filterOnline?.map((a) => a.id);
            setAllMachines(resultMapped);
        } else if (e.value?.code === 'disConnected') {
            const filterOffline = await machineData?.filter(x => x.connectionMode == 'disConnected');
            console.log('filterOffline', filterOffline);
            getResultedMachines(filterOffline);
            const resultMapped = filterOffline?.map((a) => a.id);
            setAllMachines(resultMapped);
        } else {
            getResultedMachines(machineData);
            const resultMapped = machineData?.map((a) => a.id);
            setAllMachines(resultMapped);
        }
    }

    const SelectmachineFn = async (e) => {
        console.log(e.value)
        setMachineselectValue(e.value);
        setSelectSingleMachineF(true)
        selectedBVMBrand?.length > 0 ? setFilterButton(false) : setFilterButton(true);

        if (e.value.customer === 'All') {

            const selectedBrand = selectedBVMBrand?.map((x) => { return x.code });

            let tapriMch = [];
            let tkaffeMch = [];
            let kettleMch = [];

            if (selectedBrand?.includes('TAPRI')) {
                tapriMch = machineData.filter((x) => { return x.brand === 'TAPRI' });
            }

            if (selectedBrand?.includes('TKAFFE')) {
                tkaffeMch = machineData.filter((x) => { return x.brand === 'TKAFFE' });
            }

            if (selectedBrand?.includes('MERI_TAPRI')) {
                kettleMch = machineData.filter((x) => { return x.brand === 'MERI_TAPRI' });
            }

            const filteredMachines = [...tapriMch, ...tkaffeMch, ...kettleMch];
            console.log(filteredMachines);

            const mappedArr = filteredMachines?.map((x) => { return x.id });

            setAllMachines(mappedArr)
            getResultedMachines(filteredMachines.filter(checkMachine))

            // setAllMachines(allMachineData)
            // getResultedMachines(machineData.filter(checkMachine))


        } else if (e.value.customer === 'Demo') {
            let resultedDemoMachine = machineData.filter(checkDemoMachine).map(a => a.id);
            setAllMachines(resultedDemoMachine)
            getResultedMachines(machineData.filter(checkDemoMachine))
        } else if (e.value.customer === 'Commercial') {
            let resultedPermaMachine = machineData.filter(checkpermaMachine).map(a => a.id);
            setAllMachines(resultedPermaMachine)
            getResultedMachines(machineData.filter(checkpermaMachine))
        } else {
            setAllMachines([e.value.id])
        }
    }

    const SelectTapriMachine = async (e) => {
        console.log(e.value);
        setTapriFilterButton(false)
        setSelectedTapri(e.value)
        // if(selectedTimeValue?.code != 'custom'){
        //     setTapriFilterButton(false)
        // }
    }

    const OnChangeTapriTypeValue = async (e) => {
        console.log(e.value);
        if (e.value.code == 'custom') {
            setCustKettleFilterDialog(true)
        } else {
            setSelectedTimeValue(e.value)
            setTapriFilterButton(false)
        }
    }

    const OnChangeTypeValue = (e) => {
        setCustomerClicks1(true)
        if (e.value.code == 'custom') {
            setFilterButton(true)
            setCustomFilterDialog(true);
        } else {
            setTimeselectValue(e.value);
            // setFilterButton(false) 
            selectedBVMBrand?.length > 0 ? setFilterButton(false) : setFilterButton(true);
        }
    }

    const getdateWiseCupStat = async (data) => {
        const response = await apiServices.getUsageStatByFilter(data);
        console.log(response.data);
        setAllBeverages(response.data.bevs)

        const dataTemplate = {
            type: 'bar',
            label: '',
            backgroundColor: '#42A5F5',
            data: []
        }

        const bevlength = response?.data?.bevs?.length;
        const arr2 = [];
        for (let k = 0; k < bevlength; k++) {
            const arr1 = [];
            var materialName = response?.data?.bevs[k]?.materialName;
            var color = response?.data?.bevs[k]?.color;
            for (let i = 0; i < response.data.data.length; i++) {
                for (let j = 0; j < response.data.data.length; j++) {
                    if (response.data.data[i]?.data[j]?.name == materialName) {
                        arr1.push(response.data.data[i].data[j].cups)
                    }
                }
            }
            arr2.push({ ...dataTemplate, data: arr1, label: materialName, backgroundColor: color })
        }

        console.log(arr2)
        setDayWiseCDataSet(arr2)
        setDayWiseCData(response.data.data);
    }


    const filterData = async () => {
        debugger
        setFilterButton(true)
        setIsKettleSelectedForStat(isKettleSelected)
        setSelectedTimeSlot(timeselectValue?.code)

        if (timeselectValue?.code != 'custom') {
            setTimeselectValueF(timeselectValue);
            setSelectSingleMachine(selectSingleMachineF)
        }

        setAllMachineCount(allMachines)

        // console.log(allMachines);

        const arr = [];
        machineData?.forEach((x) => {
            const isInclude = allMachines?.includes(x.id)
            if (isInclude) { arr.push(x) }
        })

        // console.log(arr);
        setMachinesForCount(arr)

        const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate: datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate: datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate: datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate: datesData.lastend };
        // if(machineselectValue?.displayLabel == 'All' || machineselectValue?.displayLabel == 'Demo' || machineselectValue?.displayLabel == 'Commercial'){
        if (machineselectValue?.displayLabel == 'All') {

            setCustomerValue(null)
            getOnlineStat(null)
            getMachineCoordinates(null);

            if (selectedMachineType?.code == 'all') {
                var type = 'all';
            } else if (selectedMachineType?.code == 'demo') {
                var type = 'demo';
                let resultedD = machineData.filter(checkDemoMachine);
                console.log(resultedD);

            } else if (selectedMachineType?.code == 'commercial') {
                var type = 'commercial';
                let resultedC = machineData.filter(checkpermaMachine);
            }

            let connectivity = 'All';

            if (selectedConnectivityStatus?.code == 'disConnected') {
                connectivity = selectedConnectivityStatus?.code;
            } else if (selectedConnectivityStatus?.code == 'connected') {
                connectivity = selectedConnectivityStatus?.code;
            }


            if (timeselectValue?.code == "all") {
                setFilteredDate(null);
                setUserStatParam({ params: { ...useStatInput3, id: allMachines, connectivity: connectivity, type: type }, time: 'all' })
                setParamForDailyCups({ id: allMachines })
                setParamForBevWiseDaily({ filter: 'chart', id: allMachines })
            } else if (timeselectValue?.code == "today") {
                setFilteredDate(today)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: today, type: type }, time: 'today' })
                setParamForDailyCups({ id: allMachines, date: today })
                setParamForBevWiseDaily({ filter: 'chart', id: allMachines, date: today })
                // getmachineStats({ ...useStatInput, id: allMachines, date: today });
            } else if (timeselectValue?.code == "yesterday") {
                setFilteredDate(yesterday)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: yesterday, type: type }, time: 'yesterday' })
                setParamForDailyCups({ id: allMachines, date: yesterday })
                setParamForBevWiseDaily({ filter: 'chart', id: allMachines, date: yesterday })
                // getmachineStats({ ...useStatInput, id: allMachines, date: yesterday });
            } else if (timeselectValue?.code == "this_week") {
                setFilteredDate(thisWeek)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: thisWeek, type: type }, time: 'this_week' })
                setParamForDailyCups({ id: allMachines, date: thisWeek })
                setParamForBevWiseDaily({ filter: 'chart', id: allMachines, date: thisWeek })
                // getmachineStats({ ...useStatInput, id: allMachines, date: thisWeek });
            } else if (timeselectValue?.code == "this_month") {
                setFilteredDate(thisMonth)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: thisMonth, type: type }, time: 'this_month' })
                setParamForDailyCups({ id: allMachines, date: thisMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: allMachines, date: thisMonth })
                // getmachineStats({ ...useStatInput, id: allMachines, date: thisMonth });
            } else if (timeselectValue?.code == "last_month") {
                setFilteredDate(lastMonth)
                setUserStatParam({ params: { ...useStatInput, id: allMachines, date: lastMonth, type: type }, time: 'last_month' })
                setParamForDailyCups({ id: allMachines, date: lastMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: allMachines, date: lastMonth })
                // getmachineStats({ ...useStatInput, id: allMachines, date: lastMonth });
            } else {
                setCustomFilterDialog(true);
            }

        } else if (machineselectValue?.displayLabel != 'All') {

            const userResponse = await apiServices.userInfo(machineselectValue.customer);
            console.log(userResponse?.data?.data);
            const subid = machineselectValue?.subid;
            console.log('subid', subid);

            // console.log("timeselectValue", timeselectValue);

            if (subid > 199999999) {
                setCustomerValue(null)
            } else {
                setCustomerValue(userResponse?.data?.data?.billing_details?.company)
            }
            // activeTabNumber == 0 ? getmachineLatLong(machineselectValue.id) : getkettleLatLong(machineselectValue.id)
            getMachineCoordinates(machineselectValue.id)

            if (timeselectValue?.code == "all") {
                setUserStatParam({ params: { ...useStatInput3, id: [machineselectValue.id] }, time: 'all' })
                setParamForDailyCups({ id: [machineselectValue.id] })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id })
                getmachineStats({ ...useStatInput3, id: [machineselectValue.id] });
                getdateWiseCupStat({ filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "today") {
                setFilteredDate(today)
                getOnlineStat(machineselectValue, today)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: today }, time: 'today' })
                setParamForDailyCups({ id: [machineselectValue.id], date: today })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: today })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: today });
                getdateWiseCupStat({ date: today, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "yesterday") {
                setFilteredDate(yesterday)
                getOnlineStat(machineselectValue, yesterday)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: yesterday }, time: 'yesterday' })
                setParamForDailyCups({ id: [machineselectValue.id], date: yesterday })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: yesterday })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: yesterday });
                getdateWiseCupStat({ date: yesterday, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "this_week") {
                setFilteredDate(thisWeek)
                getOnlineStat(machineselectValue, thisWeek)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: thisWeek }, time: 'this_week' })
                setParamForDailyCups({ id: [machineselectValue.id], date: thisWeek })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: thisWeek })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: thisWeek });
                getdateWiseCupStat({ date: thisWeek, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "this_month") {
                setFilteredDate(thisMonth)
                getOnlineStat(machineselectValue, thisMonth)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: thisMonth }, time: 'this_month' })
                setParamForDailyCups({ id: [machineselectValue.id], date: thisMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: thisMonth })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: thisMonth });
                getdateWiseCupStat({ date: thisMonth, filter: 'chart', id: machineselectValue.id })
            } else if (timeselectValue?.code == "last_month") {
                setFilteredDate(lastMonth)
                getOnlineStat(machineselectValue, lastMonth)
                setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: lastMonth }, time: 'last_month' })
                setParamForDailyCups({ id: [machineselectValue.id], date: lastMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: lastMonth })
                getmachineStats({ ...useStatInput, id: [machineselectValue.id], date: lastMonth });
                getdateWiseCupStat({ date: lastMonth, filter: 'chart', id: machineselectValue.id })
            } else {
                setCustomFilterDialog(true);
            }
            // else if (timeselectValue?.code == "custom") {
            //     setCustomFilterDialog(true);
            // }
        }
    }

    const filterTapriData = async () => {

        setTapriFilterButton(true)

        const today = { startDate: datesData.startToday, endDate: new Date() };
        const yesterday = { startDate: datesData.YestStart, endDate: datesData.EndStart };
        const thisWeek = { startDate: datesData.weekstart, endDate: datesData.weekEnd };
        const thisMonth = { startDate: datesData.monthStart, endDate: datesData.endThisMonth };
        const lastMonth = { startDate: datesData.lastStart, endDate: datesData.lastend };

        console.log(selectedTapri)

        if (selectedTapri?.displayLabel == 'All' || selectedTapri?.displayLabel == 'Demo' || selectedTapri?.displayLabel == 'Commercial') {
            setSelectedkettle(null)
            getkettleLatLong(null)

            let selectedMachineData;

            if (selectedTapri?.displayLabel == 'All') {
                selectedMachineData = allTapriMachines;
            } else if (selectedTapri?.displayLabel == 'Demo') {
                selectedMachineData = meriTapriMachines?.filter((m) => { return m?.site == "demo" }).map(a => a?.id);
            } else if (selectedTapri?.displayLabel == 'Commercial') {
                selectedMachineData = meriTapriMachines?.filter((m) => { return m?.site == "permanent" }).map(a => a?.id);
            }

            setTotalMachineCount(selectedMachineData)

            if (selectedTimeValue?.code == "all") {
                getUsageStatTapri({ id: selectedMachineData })
                setParamForDailyCups({ id: selectedMachineData })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData })
            } else if (selectedTimeValue?.code == "today") {
                getUsageStatTapri({ id: selectedMachineData, date: today })
                setParamForDailyCups({ id: selectedMachineData, date: today })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData, date: today })
            } else if (selectedTimeValue?.code == "yesterday") {
                getUsageStatTapri({ id: selectedMachineData, date: yesterday })
                setParamForDailyCups({ id: selectedMachineData, date: yesterday })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData, date: yesterday })
            } else if (selectedTimeValue?.code == "this_week") {
                getUsageStatTapri({ id: selectedMachineData, date: thisWeek })
                setParamForDailyCups({ id: selectedMachineData, date: thisWeek })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData, date: thisWeek })
            } else if (selectedTimeValue?.code == "this_month") {
                getUsageStatTapri({ id: selectedMachineData, date: thisMonth })
                setParamForDailyCups({ id: selectedMachineData, date: thisMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData, date: thisWeek })
            } else if (selectedTimeValue?.code == "last_month") {
                getUsageStatTapri({ id: selectedMachineData, date: lastMonth })
                setParamForDailyCups({ id: selectedMachineData, date: lastMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData, date: lastMonth })
            } else if (selectedTimeValue?.code == "custom") {
                // setCustKettleFilterDialog(true);
                getUsageStatTapri({ id: selectedMachineData, date: filteredDate })
                setParamForDailyCups({ id: selectedMachineData, date: filteredDate })
                setParamForBevWiseDaily({ filter: 'chart', id: selectedMachineData, date: filteredDate })
            }

        } else if (selectedTapri?.displayLabel != 'All' || selectedTapri?.displayLabel != 'Demo' || selectedTapri?.displayLabel != 'Commercial') {

            setSelectedkettle(selectedTapri?.siteName)
            getkettleLatLong(selectedTapri.id)

            if (selectedTimeValue?.code == "all") {
                getUsageStatTapri({ id: [selectedTapri?.id] })
                setParamForDailyCups({ id: [selectedTapri?.id] })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id] })
            } else if (selectedTimeValue?.code == "today") {
                getUsageStatTapri({ id: [selectedTapri?.id], date: today })
                setParamForDailyCups({ id: [selectedTapri?.id], date: today })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id], date: today })
            } else if (selectedTimeValue?.code == "yesterday") {
                getUsageStatTapri({ id: [selectedTapri?.id], date: yesterday })
                setParamForDailyCups({ id: [selectedTapri?.id], date: yesterday })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id], date: yesterday })
            } else if (selectedTimeValue?.code == "this_week") {
                getUsageStatTapri({ id: [selectedTapri?.id], date: thisWeek })
                setParamForDailyCups({ id: [selectedTapri?.id], date: thisWeek })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id], date: thisWeek })
            } else if (selectedTimeValue?.code == "this_month") {
                getUsageStatTapri({ id: [selectedTapri?.id], date: thisMonth })
                setParamForDailyCups({ id: [selectedTapri?.id], date: thisMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id], date: thisMonth })
            } else if (selectedTimeValue?.code == "last_month") {
                getUsageStatTapri({ id: [selectedTapri?.id], date: lastMonth })
                setParamForDailyCups({ id: [selectedTapri?.id], date: lastMonth })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id], date: lastMonth })
            } else if (selectedTimeValue?.code == "custom") {
                // setCustKettleFilterDialog(true);
                getUsageStatTapri({ id: [selectedTapri?.id], date: filteredDate })
                setParamForDailyCups({ id: [selectedTapri?.id], date: filteredDate })
                setParamForBevWiseDaily({ filter: 'chart', id: [selectedTapri?.id], date: filteredDate })
            }
        }
    }

    const hideCustomDialog = () => {
        setCustomStart(null)
        setCustomEnd(null)
        setCustomFilterDialog(false);
        setCustomType(null)
        // setTimeselectValue({name:'All',code:'all'});
    }

    const hideCustomeKettle = () => {
        setCustKettleFilterDialog(false)
        setCustomStart('')
        setCustomEnd('')
    }

    const handleSubmit = async (e) => {
        setCustomFilterDialog(false)
        // setTimeselectValue({name:'Custom',code:'custom'});
        setTimeselectValue(null);
        setSelectSingleMachine(selectSingleMachineF)
        setTimeselectValueF(timeselectValue);
        setCustomValue(true)
        e.preventDefault()

        if (customType?.code == 'datewise') {
            const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
            const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
            let cust_start = new Date(startt).toISOString();
            let cust_end = new Date(endd).toISOString();
            var date = { startDate: cust_start, endDate: cust_end };
        } else if (customType?.code == 'monthwise') {
            var date = getCustomDates(selectedCustomMonth.index);
        } else if (customType?.code == 'quarterwise') {
            var date = selectedQuarter?.date;
        }

        if (machineselectValue.displayLabel == "All" || machineselectValue?.displayLabel == 'Demo' || machineselectValue?.displayLabel == 'Commercial') {

            let type;
            if (machineselectValue?.displayLabel == 'All') {
                type = 'all';
            } else if (machineselectValue?.displayLabel == 'Demo') {
                type = 'demo';
            } else if (machineselectValue?.displayLabel == 'Commercial') {
                type = 'commercial';
            }

            setFilteredDate(date)
            // setUserStatParam( {params:{...useStatInput,id:allMachines,date:date,type:type},time:'custom'} )
            setUserStatParam({ params: { ...useStatInput, id: allMachines, date: date, type: type, month: selectedCustomMonth?.code, quarter: selectedQuarter?.code }, time: 'custom', customType, month: selectedCustomMonth?.code, quarter: selectedQuarter?.code })
            setParamForDailyCups({ id: allMachines, date: date })
            setParamForBevWiseDaily({ filter: 'chart', id: allMachines, date: date })
            // getmachineStats({ ...useStatInput, id: allMachines, date });

        } else if (machineselectValue?.displayLabel != 'All' && machineselectValue?.displayLabel != 'Demo' && machineselectValue?.displayLabel != 'Commercial') {
            setFilteredDate(date)
            getOnlineStat(machineselectValue, date)
            // setUserStatParam( {params:{...useStatInput,id:[machineselectValue.id],date:date},time:'custom'} )
            setUserStatParam({ params: { ...useStatInput, id: [machineselectValue.id], date: date, month: selectedCustomMonth?.code, quarter: selectedQuarter?.code }, time: 'custom' })
            setParamForDailyCups({ id: [machineselectValue.id], date: date })
            setParamForBevWiseDaily({ filter: 'chart', id: machineselectValue.id, date: date })
            getmachineStats({ ...useStatInput, id: [machineselectValue.id], date });
            getdateWiseCupStat({ date: date, filter: 'chart', id: machineselectValue.id })
            const userResponse = await apiServices.userInfo(machineselectValue.customer);
            setCustomerValue(userResponse?.data?.data?.billing_details?.company)
        }

        setDefaults()
    }

    const handleKettleSubmit = async (e) => {

        e.preventDefault()
        hideCustomeKettle()
        setSelectedTimeValue({ name: 'Custom', code: 'custom' })

        const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
        const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();

        var date = { startDate: cust_start, endDate: cust_end };
        console.log(date);

        setFilteredDate(date)

        if (selectedTapri.displayLabel == "All" || selectedTapri?.displayLabel == 'Demo' || selectedTapri?.displayLabel == 'Commercial') {

            let selectedMachineData;

            if (selectedTapri?.displayLabel == 'All') {
                selectedMachineData = allTapriMachines;
            } else if (selectedTapri?.displayLabel == 'Demo') {
                selectedMachineData = meriTapriMachines?.filter((m) => { return m?.datastore?.stage == "finishedgoods" && m?.subscription && m?.subscription?.package?.site == "demo" && m?.siteName != "" }).map(a => a?.id);
            } else if (selectedTapri?.displayLabel == 'Commercial') {
                selectedMachineData = meriTapriMachines?.filter((m) => { return m?.datastore?.stage == "finishedgoods" && m?.subscription && m?.subscription?.package?.site == "permanent" && m?.siteName != "" }).map(a => a?.id);
            }
            getUsageStatTapri({ id: selectedMachineData, date: date })

        } else if (selectedTapri.displayLabel != "All" || selectedTapri?.displayLabel != 'Demo' || selectedTapri?.displayLabel != 'Commercial') {
            getUsageStatTapri({ id: [selectedTapri?.id], date: date })
        }
    }

    const setDefaults = () => {
        setCustomType(null)
        setSelectedCustomMonth(null)
        setCustomStart(null)
        setCustomEnd(null)
    }

    const onChangeTab = (e) => {
        setActiveTabNumber(e.index)
        if (e.index == 0) {
            // getmachineLatLong(null)
            getMachineCoordinates(null)
        } else if (e.index == 1) {
            getkettleLatLong(null)
            setParamForDailyCups({ id: totalMachineCount, date: filteredDate })
            setParamForBevWiseDaily({ filter: 'chart', id: totalMachineCount.id, date: filteredDate })
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <>
                {((managementCheck() || initialValue?.userType == 'fintech') && (filteredDate || timeselectValue?.code == 'this_month') && allMachineCount?.length > 1) ? <div className="p-col-12 p-md-4" style={{ width: "500px", maxWidth: '550px' }} >
                    <div className="widget-overview-box widget-overview-box-1">
                        <span className="overview-title">
                            Total CVES Onboarded (Demo + Commercial) (Till Date)
                        </span>
                        <div className="p-jc-between">
                            <div className="overview-detail p-d-flex p-jc-between">
                                <div className="overview-text">
                                    {
                                        masterLoader ?
                                            <b><i className="pi pi-spin pi-spinner" /></b> :
                                            <b>{allMachineCount?.length}</b>
                                    }
                                </div>
                            </div>
                        </div>
                        <i className={`pi pi-bars side-icon side-icon`} style={{ fontSize: '2rem' }}></i>
                    </div>
                </div> : <div className="p-inputgroup p-m-1"> <span><p><b>FILTERS: </b></p></span> </div>}
            </>
        )
    }


    // const leftToolbarTemplate = () => (<><div className="p-inputgroup p-m-1"> <span><p><b>FILTERS: </b></p></span> </div> </>)

    const groupedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.label} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.label}</div>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                {filteredDate &&
                    <div className="p-inputgroup p-m-1" style={{ minWidth: '300px', maxWidth: '300px', fontSize: '1.3rem' }}>
                        <b>Date : {` ${new Date(filteredDate?.startDate).getDate()}/${new Date(filteredDate?.startDate)?.getMonth() + 1}/${new Date(filteredDate?.startDate)?.getFullYear()} - ${new Date(filteredDate?.endDate).getDate()}/${new Date(filteredDate?.endDate)?.getMonth() + 1}/${new Date(filteredDate?.endDate)?.getFullYear()} `}  </b>
                    </div>
                }
                {
                    (!managementCheck() && initialValue?.userType != 'fintech') &&
                    <div className="p-inputgroup p-m-1" style={{ minWidth: '450px', maxWidth: '450px' }}>
                        <Button className='custom-group-btn' label="Categories" />
                        <MultiSelect value={selectedBVMBrand} onChange={changeBVMBrand} options={BevVendingMchOptions} optionLabel="name" display="chip"
                            placeholder="Select Categories" maxSelectedLabels={3} className={`${selectedBVMBrand?.length == 0 && 'p-invalid'} w-full md:w-20rem`} filter />
                    </div>
                }

                {initialValue?.userType == 'invenstor' && <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="Connectivity" tooltip="Connectivity" />
                    <Dropdown id='select-mc' value={selectedConnectivityStatus} onChange={handleConnectivityStatus} options={connectivityStatusOptions} optionLabel="name" placeholder="Select Connectivity" filter className="p-mr-2 p-p-1 multiselect-custom " />
                </div>}


                {initialValue?.userType != 'fintech' && <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="Type" tooltip="Subscription Type" />
                    <Dropdown id='select-mc' value={selectedMachineType} onChange={selectMachType} options={machineTypeOptions} optionLabel="name" placeholder="Select Type" filter className="p-mr-2 p-p-1 multiselect-custom " />
                </div>}



                {
                    (!managementCheck() && isKettleSelected) &&
                    <div className="p-inputgroup p-m-1" style={{ maxWidth: '450px' }}>
                        <Button className='custom-group-btn' label="Status" />
                        <MultiSelect value={selectedMachStatus} onChange={changeMchStatus} options={mchStatusOptions} optionLabel="name" display="chip"
                            placeholder="Select Status" maxSelectedLabels={2} className={`${selectedMachStatus?.length == 0 && 'p-invalid'} w-full md:w-20rem`} filter />
                    </div>
                }

                <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="CVES" tooltip={`Total CVES : ${resultedMachine?.length - 1}`} />
                    <Dropdown id='select-mc' value={machineselectValue} onChange={SelectmachineFn} options={resultedMachine} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-mr-2 p-p-1 multiselect-custom " />
                </div>

                 <div className="p-inputgroup p-m-0">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-fil' value={timeselectValue} onChange={OnChangeTypeValue} options={(managementCheck() || initialValue?.userType == 'fintech' ) ? (machineselectValue?.displayLabel == 'All' ? TimeSelectValuesForManagementAll : TimeSelectValuesForManagementSingle) : TimeSelectValues} optionLabel="name" placeholder="Custom" filter
                        className="p-m-0 p-p-1 multiselect-custom" />
                </div>


                <div>
                    {!filterButton && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={filterButton} onClick={filterData} />}
                </div>

            </>
        )
    }

    const rightToolbarTemplate1 = () => {
        return (
            <>
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="TAPRI KETTLE" />
                    <Dropdown id='select-mc' value={selectedTapri} onChange={SelectTapriMachine} options={tapriMachineData} optionLabel="displayLabel" placeholder="Select Tapri Kettle" filter className="p-mr-2 multiselect-custom " style={{ width: '110%' }} />
                </div>

                <div className="p-inputgroup">
                    <Button className='custom-group-btn' label="Duration" />
                    <Dropdown id='select-fil' value={selectedTimeValue} onChange={OnChangeTapriTypeValue} options={TimeSelectValues1} optionLabel="name" placeholder="Select Time" filter
                        className="p-m-0 multiselect-custom" />
                </div>

                <div>

                    {!tapriFilterButton && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" disabled={tapriFilterButton} onClick={filterTapriData} />}
                </div>

            </>
        )
    }

    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            {
                customType && <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
            }
        </>
    )

    const customKettleFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomeKettle}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            <button className="p-button p-button-success" type="submit" form="custom-kettle-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
        </>
    )

    return (
        <>
            <div className="layout-dashboard">
                <div className='card'>

                    <TabView activeIndex={activeTabNumber} onTabChange={onChangeTab}>
                        <TabPanel header="CVES">
                            <Toolbar className="p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                            <AnalyticalStat
                                filteredDate={filteredDate}
                                customType={customType}
                                userStatParam={userStatParam}
                                selectedTimeSlot={selectedTimeSlot}
                                allMachineCount={allMachineCount}
                                customerValue={customerValue}
                                managementCheck={managementCheck()}
                                onlineData={onlineData}
                                machinesForCount={machinesForCount}
                                machineselectValue={machineselectValue}
                                allMachineData={allMachineData}
                                selectedBVMBrand={selectedBVMBrand}
                                isKettleSelectedForStat={isKettleSelectedForStat}
                                selectedMachStatus={selectedMachStatus}
                                materialType={materialType}
                                setFlavourWiseData={setFlavourWiseData}
                                setMasterLoader={setMasterLoader}
                                masterLoader={masterLoader}
                            />

                        </TabPanel>



                    </TabView>


                    {locationLoader ? <div style={{ height: '400px', width: '100%', borderRadius: '12px', border: '1px solid #06685d', boxShadow: '4px 4px 5px 3px #88888b47' }}>
                        <center style={{ "marginTop": "10%" }}><ProgressSpinner strokeWidth={7} animationDuration='2s' /></center>
                    </div> : <div id="map" style={{ height: '400px', width: '100%', borderRadius: '12px', border: '1px solid #06685d', boxShadow: '4px 4px 5px 3px #88888b47' }}></div>}


                    <AnalyticalCharts activeTabNumber={activeTabNumber} bevChartData={bevChartData} userStatParam={userStatParam} paramForDailyCups={paramForDailyCups} customerValue={customerValue} paramForBevWiseDaily={paramForBevWiseDaily} cupCData={cupCData} TimeCData={TimeCData} allCupConsumeData={allCupConsumeData}
                        allBeverages={allBeverages} getmachineStats={getmachineStats}
                        materialType={materialType} flavourWiseData={flavourWiseData}
                        masterLoader={masterLoader}
                    />

                </div>
            </div>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>
                {
                    selectedBVMBrand?.length == 0 ?
                        <center><h6 className="p-error">Please select atleast one category</h6></center>
                        :
                        <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <label htmlFor="lasttt">Select Filter Type </label>
                                    <Dropdown id='lasttt' optionLabel="name" options={(managementCheck() || initialValue.userType === 'fintech') ? customWiseManagement : customWise} value={customType} onChange={(e) => { setCustomType(e.value) }} placeholder="Select Filter Type" filter className="p-mr-2 multiselect-custom " />
                                </div>

                                {
                                    customType?.code == 'datewise' &&
                                    <>
                                        <div className='p-col-6'>
                                            <label htmlFor="from">From </label>
                                            <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)} value={customStart} onChange={(e) => { setCustomStart(e.target.value) }} required autoFocus />
                                        </div>
                                        <div className='p-col-6'>
                                            <label htmlFor="to">To</label>
                                            <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => { setCustomEnd(e.target.value) }} required />
                                        </div>
                                    </>
                                }

                                {
                                    customType?.code == 'monthwise' &&
                                    <>
                                        <div className='p-col-12'>
                                            <label htmlFor="lasttt">Select Months </label>
                                            <Dropdown id='lasttt' optionLabel="name" options={lastMonthsListDropDown} value={selectedCustomMonth} onChange={(e) => { setSelectedCustomMonth(e.value); setSelectedQuarter(null) }} placeholder="Select Last Months" filter className="p-mr-2 multiselect-custom " required />
                                        </div>
                                    </>
                                }

                                {
                                    customType?.code == 'quarterwise' &&
                                    <>
                                        <div className='p-col-12'>
                                            <label htmlFor="lastQuarter">Select Quarter </label>
                                            <Dropdown id='lastQuarter' optionLabel="name" options={quarterListOptions} value={selectedQuarter} onChange={(e) => { setSelectedQuarter(e.value); setSelectedCustomMonth(null) }} placeholder="Select Last Quarter" filter className="p-mr-2 multiselect-custom " required />
                                        </div>
                                    </>
                                }

                            </div>
                        </form>
                }

            </Dialog>

            <Dialog visible={custKettleFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customKettleFooter} onHide={hideCustomeKettle} modal className="p-fluid" maximizable={true} blockScroll={true}>
                <form onSubmit={handleKettleSubmit} id="custom-kettle-form" className="p-fluid">
                    <div className='p-grid'>
                        <div className='p-col-6'>
                            <label htmlFor="from">From </label>
                            <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)} value={customStart} onChange={(e) => { setCustomStart(e.target.value) }} required autoFocus />
                        </div>
                        <div className='p-col-6'>
                            <label htmlFor="to">To</label>
                            <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => { setCustomEnd(e.target.value) }} required />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )
}


