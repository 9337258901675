import React, { useState, useEffect} from "react";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Chart } from "primereact/chart";
// Components
import { SubAnalyticalStat } from "../NewAnalytics.js/SubAnalyticalStat";
// Services
import CommonCal from "../../service/common/CommonCal";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

const commonCal = new CommonCal()
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const PerformanceByLocation = () =>{

    const durationOptions = [
        {name:'Today',code:'today'},
        {name:'Yesterday',code:'yesterday'},
        {name:'This Week',code:'this_week'},
        {name:'This Month',code:'this_month'},
        {name:'Last Month',code:'last_month'},
        {name:'Custom',code:'custom'}
    ]

    const [selectedDuration, setSelectedDuration] = useState({name:'This Month',code:'this_month'})
    const [enteredCityName, setEnteredCityName] = useState(null)
    const [allDateData, setAllDateData] = useState(null)
    const [selectedDurationDate, setSelectedDurationDate] = useState(null)
    const [dataResponse, setDataResponse] = useState(null)
    const [superstarValue, setSuperstarValue] = useState(null)
    const [dataLabels, setDataLabels] = useState(null)
    const [dataValues, setDataValues] = useState(null)

    useEffect(()=>{
        getInitial()
    },[])

    const getInitial = async() =>{
        const response = await commonCal.getDates();
        setAllDateData(response)
        setSelectedDurationDate({ startDate: response.monthStart, endDate:response.endThisMonth })
        const data = { "date" : { startDate: response.monthStart, endDate:response.endThisMonth }}
        getData(data)
    }

    const getData = async(data) =>{
        data.userType = initialValue.userType
        const response = await apiServices.usageByLocation(data);
        if(response?.data?.success){
            setDataResponse(response?.data)
            const superStarCal = response?.data?.data?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
            const superStarCalc = superStarCal[0];
            setSuperstarValue(superStarCalc)
            const labels = response?.data?.data?.brvStats?.map((x)=>{ return x?._id})
            const values = response?.data?.data?.brvStats?.map((x)=>{ return x?.totalCup})
            setDataLabels(labels)
            setDataValues(values)

        }else{
            setDataResponse(null)
            setSuperstarValue(null)
            setDataLabels(null)
            setDataValues(null)
        }   
    }

    const changeCityFil = (data) =>{
        if(data){
            setEnteredCityName(data)
        }else{
            getInitial()
            setSelectedDuration({name:'This Month',code:'this_month'})
        }
    }

    const handleKeyDown = (e) =>{
		if (e.key === 'Enter') {
            getData({ "city": enteredCityName,"date" : selectedDurationDate});
		}
	}

    const changeDuration = (e) =>{

        setSelectedDuration(e.value)
        const today = { startDate: allDateData.startToday, endDate: new Date() };
        const yesterday = { startDate: allDateData.YestStart, endDate:allDateData.EndStart };
        const thisWeek = { startDate: allDateData.weekstart, endDate:allDateData.weekEnd };
        const thisMonth = { startDate: allDateData.monthStart, endDate:allDateData.endThisMonth };
        const lastMonth = { startDate: allDateData.lastStart, endDate:allDateData.lastend };

        switch (e.value.code) {
            case 'today':
                getData({ "city": enteredCityName,"date" : today })
                break;            
            case 'yesterday':
                getData({ "city": enteredCityName,"date" : yesterday });
                break;
            case 'this_week':
                getData({ "city": enteredCityName,"date" : thisWeek })
                break;
            case 'this_month':
                getData({ "city": enteredCityName,"date" : thisMonth })
                break;
            case 'last_month':
                getData({ "city": enteredCityName,"date" : lastMonth })
                break;
            default:
                break;
        }
    }

    const barData = {
        labels: dataLabels,
        datasets: [
            {
                label: 'Cups',
                backgroundColor: '#00544d',
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };

    const barOptions = {
        plugins: {
            legend: {
                // labels: {
                //     color: '#A0A7B5'
                // }
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: `Beverages`
                }
            },
            
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: 'No of Cups'
                }
            }
        }
    };


    const leftToolbarTemplate = () =>(
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' icon="pi pi-search" />
                <InputText list="SearchCities" type="search" onInput={(e) => changeCityFil(e.target.value)} placeholder="Enter City Name" onKeyDown={(e) => handleKeyDown(e)} />

                <datalist id="SearchCities">
                    <option value="Pune">Pune</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Kolkata">Kolkata</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Goa">Goa</option>
                </datalist>
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} onChange={changeDuration} options={durationOptions} optionLabel="name" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
            </div>
        </>
    ) 


    return (
        <>
            <div className="card">        
                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>   

                <div className="p-grid p-my-5">
                    <SubAnalyticalStat icon="pi pi-calendar" header={`Total CVES`} value={dataResponse?.mchCount ? dataResponse?.mchCount : 0} />
                    <SubAnalyticalStat icon="pi pi-calendar" header={`SuperStar`} value={superstarValue?._id ? superstarValue?._id : 'NA'} />
                    <SubAnalyticalStat icon="pi pi-calendar" header={`Total Cup Consuption`} value={dataResponse?.data?.usagesStats[0]?.totalCup ? dataResponse?.data?.usagesStats[0]?.totalCup : 0} />
                </div>

                <Chart type="bar" data={barData} options={barOptions} />

            </div>

        </>
    )
}

